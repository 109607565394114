import './App.css';
import Routes from 'routes';
import { Suspense } from 'react';
function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes />
    </Suspense>
  );
}

export default App;
