import React from "react";
import { Route, Router } from "react-router";
import { history } from "components";
import { About, Home, OurServices } from "pages";

function Routes() {
  return (
    <Router history={history}>
      <Route exact path="/" component={Home} />
      <Route exact path="/meet-your-barber" component={About} />
      <Route exact path="/our-services" component={OurServices} />
    </Router>
  );
}

export default Routes;
